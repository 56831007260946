export const VIEW_ACCOUNTS = 'View Accounts';
export const CREATE_ACCOUNTS = 'Create Accounts';
export const UPDATE_ACCOUNTS = 'Update Accounts';
export const DELETE_ACCOUNTS = 'Delete Accounts';
export const EXPORT_ACCOUNTS = 'Export Accounts';
export const DIRECTORY_PERMISSIONS = [VIEW_ACCOUNTS, CREATE_ACCOUNTS, UPDATE_ACCOUNTS, DELETE_ACCOUNTS, EXPORT_ACCOUNTS];

export const VIEW_SEQUENCER = 'View Sequencer';
export const CREATE_STRATEGIES = 'Create Strategies';
export const ARCHIVE_STRATEGIES = 'Archive Strategies';
export const EDIT_STRATEGIES = 'Edit Strategies';
export const RUN_STRATEGIES = 'Run Strategies';
export const DELETE_STRATEGIES = 'Delete Strategies';
export const SEQUENCER_PERMISSIONS = [VIEW_SEQUENCER, CREATE_STRATEGIES, ARCHIVE_STRATEGIES, EDIT_STRATEGIES, RUN_STRATEGIES, DELETE_STRATEGIES];

export const VIEW_DNC = 'View DNC';
export const CREATE_DNC_RECORDS = 'Create DNC Records';
export const DELETE_DNC_RECORDS = 'Delete DNC Records';
export const UPLOAD_DNC_LISTS = 'Upload DNC Lists';
export const DNC_PERMISSIONS = [VIEW_DNC, CREATE_DNC_RECORDS, DELETE_DNC_RECORDS, UPLOAD_DNC_LISTS];

export const VIEW_USERS = 'View Users';
export const CREATE_USERS = 'Create Users';
export const UPDATE_USERS = 'Update Users';
export const DELETE_USERS = 'Delete Users';
export const USER_PERMISSIONS = [VIEW_USERS, CREATE_USERS, UPDATE_USERS, DELETE_USERS];

export const MANAGE_HEYREACH_INSTANCES = 'Manage HeyReach Instances';
export const MANAGE_HEYREACH_CAMPAIGNS = 'Manage HeyReach Campaigns';
export const MANAGE_SMARTLEAD_INSTANCES = 'Manage SmartLead Instances';
export const VIEW_DOCUMENTATION = 'View Documentation';
export const CONFIGURE_PERMISSIONS = [MANAGE_HEYREACH_INSTANCES, MANAGE_HEYREACH_CAMPAIGNS, MANAGE_SMARTLEAD_INSTANCES, VIEW_DOCUMENTATION];

export const MANAGE_SETTINGS = 'Manage Settings';
export const MANAGE_TAGS = 'Manage Tags';
export const SETTINGS_PERMISSIONS = [MANAGE_SETTINGS, MANAGE_TAGS];

export const PERMISSION_GROUPS = {
  'Directory': DIRECTORY_PERMISSIONS,
  'Sequencer': SEQUENCER_PERMISSIONS,
  'DNC': DNC_PERMISSIONS,
  'Users': USER_PERMISSIONS,
  'Configure': CONFIGURE_PERMISSIONS,
  'Settings': SETTINGS_PERMISSIONS,
};
