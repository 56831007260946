import { createRouter, createWebHistory } from 'vue-router';
import UnauthenticatedLayout from '@/layouts/UnauthenticatedLayout.vue';
import RoleForm from '@/pages/user/RoleForm.vue';
import { isTokenValid } from '@/helpers/auth';

const routes = [
  {
    path: '/auth',
    component: UnauthenticatedLayout,
    children: [
      {path: 'login', name: 'login', component: () => import('@/pages/login/LoginPage')},
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('@/pages/login/ForgotPasswordPage')
      },
      {path: 'token/:token', name: 'authToken', component: () => import('@/pages/login/Auth.vue')}
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthenticatedLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {path: '', name: 'dashboard', component: () => import('@/pages/dashboard/DashboardPage')},
      {
        path: 'accounts',
        name: 'accounts',
        component: () => import('@/pages/directory/Accounts.vue')
      },
      {
        path: 'account/:id',
        name: 'account',
        component: () => import('@/pages/directory/Account.vue')
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('@/pages/search/SearchPage.vue')
      },
      {
        path: 'sequencer',
        name: 'sequencer',
        component: () => import('@/pages/sequencer/Sequencer.vue')
      },
      {
        path: 'sequencer/:id',
        name: 'sequence',
        component: () => import('@/pages/sequencer/SequencerAccount.vue')
      },
      {
        path: 'sequencer/:id/:strategy_id',
        name: 'strategy',
        component: () => import('@/pages/sequencer/SequencerAccount.vue')
      },
      {
        path: 'extension',
        name: 'extension',
        component: () => import('@/pages/extension/ExtensionPage.vue')
      },
      {
        path: 'heyreach',
        name: 'heyreach',
        component: () => import('@/pages/heyreach/HeyreachPage.vue')
      },
      {
        path: 'smartlead',
        name: 'smartlead',
        component: () => import('@/pages/smartlead/SmartleadPage.vue')
      },
      {
        path: 'documentation',
        name: 'documentation',
        component: () => import('@/pages/documentation/DocumentationPage')
      },
      {path: 'settings', name: 'settings', component: () => import('@/pages/settings/SettingsPage')},
      {
        path: 'users',
        name: 'users',
        component: () => import('@/pages/user/Users.vue')
      },
      {
        path: '/roles',
        name: 'roles',
        component: () => import('@/pages/user/Roles.vue'),
      },
      {
        path: '/roles/create',
        name: 'CreateRole',
        component: RoleForm,
      },
      {
        path: '/roles/:id',
        name: 'EditRole',
        component: RoleForm,
        props: true,
      },
      {
        path: '/users/create',
        name: 'CreateUser',
        component: () => import('@/pages/user/UserForm.vue'),
      },
      {
        path: '/users/:id',
        name: 'EditUser',
        component: () => import('@/pages/user/UserForm.vue'),
        props: true,
      },
      {
        path: '/dnc',
        name: 'dnc',
        component: () => import('@/pages/dnc/Dnc.vue')
      },
    ]
  },
  // 404 Handler
  {
    path: '/:pathMatch(.*)*',
    component: UnauthenticatedLayout,
    children: [{path: '', name: '404', component: () => import('@/NotFound')}]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.fullPath === '/auth/login') {
    next();
  }

  const token = localStorage.getItem('aiq_token');
  if (to.meta.requiresAuth) {
    if (!token) {
      next('/auth/login');
      return;
    }
    if (!(await isTokenValid(token))) {
      next('/auth/login');
      return;
    }
    next();
  } else {
    if (token && !to.query.error && (to.name === 'login' || to.name === 'forgot-password' || to.name === 'authToken')) {
      next('/');
      return;
    }
    next();
  }
});

export default router;
