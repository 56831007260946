import { useApi } from '@/helpers/udab-api';

export function parseJwt(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
}

export async function isTokenValid(token) {
  if (!token) return false;
  const payload = parseJwt(token);
  if (!payload) return false;
  const now = Math.floor(Date.now() / 1000);
  if (payload.exp < now) return false;
  if (payload.exp - now <= 3600) {
    const {post} = useApi();
    try {
      const response = await post('/saml/refresh', {token: token}, {showLoader: false});
      localStorage.setItem('aiq_token', response.token);
      return true;
    } catch (error) {
      return false;
    }
  }
  return true;
}

export function hasPermission(permission, defaultValue) {
  if (defaultValue !== undefined) {
    return defaultValue;
  }
  const userToken = localStorage.getItem('aiq_token');

  if (userToken) {
    const payload = parseJwt(userToken);
    if (payload?.superadmin) {
      return true;
    }
    if (payload && payload?.permissions && payload?.permissions.includes(permission)) {
      return true;
    }
  }

  return false;
}

export function hasAnyPermission(permissions) {
  const userToken = localStorage.getItem('aiq_token');
  if (userToken) {
    const payload = parseJwt(userToken);
    if (payload?.superadmin) {
      return true;
    }
    if (payload && payload.permissions && Array.isArray(permissions)) {
      return permissions.some(perm => payload.permissions.includes(perm));
    }
  }
  return false;
}
