import { inject } from 'vue';
import axios from 'axios';
import { isTokenValid } from '@/helpers/auth';

const redirectToLogin = () => {
  localStorage.setItem('aiq_token', '');
  window.location.href = '/auth/login';
};

export function useApi() {
  const loader = inject('loader');
  const defaultOptions = {
    showLoader: true,
    responseType: 'json'
  };

  const makeRequest = async (
    method,
    url,
    data = null,
    params = {},
    options = {}
  ) => {
    options = {...defaultOptions, ...options};

    if (loader && options.showLoader) {
      loader.increment();
    }

    const endpoint = url;
    url = window.$udabApiUrl + url;
    if (!endpoint.startsWith('/saml/')) {
      const token = localStorage.getItem('aiq_token');
      if (!token || !(await isTokenValid(token))) {
        redirectToLogin();
        return;
      }
    }

    const aiqToken = localStorage.getItem('aiq_token');
    try {
      const response = await axios({
        method,
        url,
        data,
        params,
        responseType: options.responseType,
        headers: {
          'X-AIQ-TOKEN': `Bearer ${aiqToken}`
        }
      });

      if (response?.data?.unauthorized) {
        redirectToLogin();
        return;
      }

      return response.data;
    } finally {
      if (loader && options.showLoader) {
        loader.decrement();
      }
    }
  };

  const get = (url, params, options = {}) =>
    makeRequest('get', url, null, params, options);

  const post = (url, data, options = {}) =>
    makeRequest('post', url, data, {}, options);

  const put = (url, data, options = {}) =>
    makeRequest('put', url, data, {}, options);

  const patch = (url, data, options = {}) =>
    makeRequest('patch', url, data, {}, options);

  const deleteRequest = (url, data, params, options = {}) =>
    makeRequest('delete', url, data, params, options);

  const postFormData = async (url, data, params = {}, options = {}) => {
    options = {...defaultOptions, ...options};

    if (options.showLoader) {
      loader.increment();
    }

    url = window.$udabApiUrl + url;
    if (!url.endsWith('/saml/token') && !url.endsWith('/saml/refresh')) {
      const token = localStorage.getItem('aiq_token');
      if (!token || !(await isTokenValid(token))) {
        redirectToLogin();
        return;
      }
    }

    const aiqToken = localStorage.getItem('aiq_token');
    try {
      const response = await axios.post(url, data, {
        params,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-AIQ-TOKEN': `Bearer ${aiqToken}`
        },
        responseType: options.responseType
      });
      return response.data;
    } finally {
      if (options.showLoader) {
        loader.decrement();
      }
    }
  };

  const getStrategyActivities = async (strategyId, channel = null) => {
    console.log(`[API] Calling getStrategyActivities for strategy ${strategyId}, channel: ${channel}`);
    let url = `/strategy/${strategyId}/activities`;
    if (channel) {
      url += `?channel=${channel}`;
    }
    console.log(`[API] Full URL: ${url}`);

    try {
      const response = await get(url);
      console.log('[API] getStrategyActivities response:', response);
      return response;
    } catch (error) {
      console.error('[API] getStrategyActivities error:', error);
      throw error;
    }
  };

  const getActivityContacts = async (activityId, offset = 0, limit = 100) => {
    console.log(`[API] Fetching contacts for activity ${activityId} (offset: ${offset}, limit: ${limit})`);
    try {
      const aiqToken = localStorage.getItem('aiq_token');
      const response = await axios({
        method: 'get',
        url: window.$udabApiUrl + `/strategy-activity/${activityId}/contacts`,
        params: {offset, limit},
        headers: {
          'X-AIQ-TOKEN': `Bearer ${aiqToken}`
        }
      });
      console.log(`[API] getActivityContacts response: Got ${response.data?.length || 0} contacts`);
      if (response.data && response.data.length > 0) {
        console.log('[API] Sample contact data:', {
          project: {
            id: response.data[0].project_id,
            name: response.data[0].project_name
          },
          company: {
            id: response.data[0].company_id,
            name: response.data[0].company_name
          },
          industry: response.data[0].primary_industry
        });
      }
      return response.data;
    } catch (error) {
      console.error('[API] getActivityContacts error:', error);
      throw error;
    }
  };

  const exportActivityContacts = async (activityId) => {
    console.log(`[API] Exporting contacts for activity ${activityId}`);
    try {
      const aiqToken = localStorage.getItem('aiq_token');
      const response = await axios({
        method: 'get',
        url: window.$udabApiUrl + `/strategy-activity/${activityId}/contacts/export`,
        responseType: 'blob',
        headers: {
          'X-AIQ-TOKEN': `Bearer ${aiqToken}`
        }
      });
      console.log(`[API] Export response status: ${response.status}, blob size: ${response.data.size}`);
      return response.data;
    } catch (error) {
      console.error('[API] exportActivityContacts error:', error);
      throw error;
    }
  };

  const getActivityLogs = async (activityId) => {
    console.log(`[API] Fetching logs for activity ${activityId}`);
    try {
      const aiqToken = localStorage.getItem('aiq_token');
      const response = await axios({
        method: 'get',
        url: window.$udabApiUrl + `/strategy-activity/${activityId}/logs`,
        responseType: 'text',
        headers: {
          'X-AIQ-TOKEN': `Bearer ${aiqToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('[API] getActivityLogs error:', error);
      throw error;
    }
  };

  return {
    get,
    post,
    put,
    patch,
    deleteRequest,
    postFormData,
    getStrategyActivities,
    getActivityContacts,
    exportActivityContacts,
    getActivityLogs
  };
}
